<template>
  <div>
    <div v-if="'hetun' === heTunInfo"><faqHetun></faqHetun></div>
    <div v-else>
      <div class="title">常见问题</div>
      <div style="text-align: center; line-height: 20px; margin-top: 10px;">
        发布时间：2022-01-07 17:52:47</div>
      <div data-v-0ab10f90="" style="font-size: 14px;"><p>&nbsp;</p>
        <p><strong>{{ webKeyword.OPEN_COM_NAME }}致力于为从事国内/跨境电子商务进出口业务的电商企业提供一站式的供应链服务，专注解决国内/跨境电子商务客户发展过程中存在的核心问题，帮助客户在可靠性、速度和成本效益方面创造价值。</strong></p>
        <p><strong>{{ webKeyword.OPEN_COM_NAME }}的代发模式是什么？</strong></p>
        <p>{{ webKeyword.OPEN_COM_NAME }}的客户群体是分销商，分销商的范围覆盖线上各平台上的商铺（比如天猫国际、苏宁易购、淘宝、洋码头）、微店、独立的B2C（比如蜜芽宝贝）、线下实体店，或者大宗贸易采购商等。分销商首先从{{ webKeyword.OPEN_COM_NAME }}的平台上采购商品，商品将会囤在分销商的微仓，分销商可以在自己的销售渠道出售囤在微仓的商品，然后把销售订单信息通过多种方式导入{{ webKeyword.OPEN_COM_NAME }}后台，我们{{ webKeyword.OPEN_COM_NAME }}根据订单信息从给分销商分配的微仓将货物从保税区配送到终端消费者手上。当然，分销商也可以先根据自己的销售订单视情况再采购。但是，由于跨境市场的不确定性，我们建议分销商提前采购备货，规避供货紧张或者库存不足的风险。
          囤货到自己的微仓，再把自己的销售订单数据流同步到{{ webKeyword.OPEN_COM_NAME }}后台，最后再从自己的微仓按订单信息从香港配送到终端消费者手上。当然，分销商也可以先根据自己的销售订单流视情况再采购，但是，由于市场的不确定性，部分商品可能经常性供货紧张或者库存不足，建议分销商提前采购备货。</p>
        <p><strong>{{ webKeyword.OPEN_COM_NAME }}的货源来源是什么？</strong></p>
        <p>{{ webKeyword.OPEN_COM_NAME }}在国内和德国、荷兰、英国、法国、澳大利亚、韩国等国家和地区都设有资深的买手团队，与当地的厂家和实力经销商保持着深度的合作关系，同时也会在药店和商超等正轨专卖渠道提货。我们承诺所有出自{{ webKeyword.OPEN_COM_NAME }}的商品，均为100%原装本地正品。</p>
        <p><strong>{{ webKeyword.OPEN_COM_NAME }}的货源稳定吗？</strong></p>
        <p>{{ webKeyword.OPEN_COM_NAME }}和国内外品牌供应商建立了稳定的合作关系，保证了货源可以持续稳定的供应。我们有专业的物流仓储团队负责整个供应链环节的监管工作，业务团队及时监控市场并通过高效率的采购工作保证货物的安全库存。</p>
        <p><strong>{{ webKeyword.OPEN_COM_NAME }}的商品都能有价格优势，保证分销商的利润空间吗？</strong></p>
        <p>{{ webKeyword.OPEN_COM_NAME }}对每个商品都标注了起批价、市场零售价、以及可赚金额。起批价是指分销商在{{ webKeyword.OPEN_COM_NAME }}采购商品达一定数量时的价格；市场零售价是指具体某个商品在整个市场的平均售价。{{ webKeyword.OPEN_COM_NAME }}的商品定价均十分优惠，主要通过国外大量采购以及便捷的物流管理降低成本。</p>
        <p><strong>{{ webKeyword.OPEN_COM_NAME }}发货的物流配送方式是什么？</strong></p>
        <p>目前，跨境电商进口业务通关三种模式：快件清关、集货清关和备货清关。快件清关指确认订单后，国外供应商通过国际快递将商品直接从境外邮寄至消费者手中，无海关单据。集货清关（先有订单，再发货）指商家将多个已售出商品统一打包，通过国际物流运至国内的保税仓库，电商企业为每件商品办理海关通关手续，经海关查验放行后，由电商企业委托国内快递派送至消费者手中，每个订单附有海关单据。备货清关（先备货，后有订单）指商家将境外商品批量备货至海关监管下的保税仓库，消费者下单后，电商企业根据订单为每件商品办理海关通关手续，在保税仓库完成贴面单和打包，经海关查验放行后，由电商企业委托国内快递派送至消费者手中，每个订单附有海关单据。{{ webKeyword.OPEN_COM_NAME }}和国内自贸区、保税区、众多跨境物流（如：COE、COSCO、香港邮政等）合作，订单包裹通关后转国内快递配送。</p>
        <p><strong>{{ webKeyword.OPEN_COM_NAME }}发货通关需要提供身份证报关吗？</strong></p>
        <p>{{ webKeyword.OPEN_COM_NAME }}商品需要清关后入境，根据海关要求，包裹收件人需要提交身份证正反面清晰照片进行个人物品入境申报（或者提供身份证号码），身份证信息将严格保管，仅限清关使用。</p>
        <p><strong>为什么需要提供收货人的身份证信息？</strong></p>
        <p>身份证号码的验证是为了使货物能够顺利配送，跨境商品从保税区发货需要进行海关清关处理。</p>
        <p><strong>我的信息有安全保护措施吗？</strong></p>
        <p>根据中国海关总署修订后的《中华人民共和国海关对进出境快件监管办法》（参见：中华人民共和国海关总署http://www.customs.gov.cn），进口到中国大陆境内的所有跨境收件人需向海关提供身份证信息。信息将为收件人物品到达中国海关时，提交海关备案所用。您的个人信息将会收到严格保密。</p>
        <p><strong>有何注意事项？</strong></p>
        <p>提交的身份证信息必须为销售订单收货人的身份证信息。</p>
        <p><strong>{{ webKeyword.OPEN_COM_NAME }}关税问题</strong></p>
        <p>海外直购商品需依法向中国海关申报及纳税，如产生税金，将由买家承担。请参考《中华人民共和国进境物品归类表》、《中华人民共和国进境物品完税价格表》。{{ webKeyword.OPEN_COM_NAME }}为一家正规的电商公司，支持国家相关法律政策，提倡主动报关并缴纳关税。
          关税必须接收人到当地海关处理，如产生关税请根据通知自行进行相关的处理。</p>
        <p>关税必须接收人到当地海关处理，如产生关税请根据通知自行进行相关的处理。</p>
        <p><strong>关税该交给谁？</strong></p>

        <p>携有应税个人自用物品的入境旅客及运输工具服务人员、进口邮递物品的收件人，以及以其他方式进口应税个人自用物品的收件人是行邮税的纳税义务人。纳税义务人可以自行办理纳税手续，也可以委托他人办理纳税手续。接受委托办理纳税手续的代理人，应当遵守《中华人民共和国进出口关税条例》中对其委托人的各项规定，并承担相应的<a href="http://baike.baidu.com/view/21606.htm" rel="noopener" target="_blank">法律责任</a>。行邮税的纳税义务人，应当在物品放行前缴纳税款。</p>
        <p><strong>税率是多少？</strong></p>
        <p>《海关总署公告2012年第15号》文件中提到：</p>
        <p>一、《中华人民共和国进境物品完税价格表》已列明完税价格的物品，按照《完税价格表》确定；</p>
        <p>二、《完税价格表》未列明完税价格的物品，按照相同物品相同来源地最近时间的主要市场零售价格确定其完税价格；</p>
        <p>三、实际购买价格是《完税价格表》列明完税价格的2倍及以上，或是《完税价格表》列明完税价格的1/2及以下的物品，进境物品所有人应向海关提供销售方依法开具的真实交易的购物发票或收据，并承担相关责任。海关可以根据物品所有人提供的上述相关凭证，依法确定应税物品完税价格。</p>
        <p>海关总署公告2010年第43号《关于调整进出境个人邮递物品管理措施有关事宜》为进一步规范对进出境个人邮递物品的监管，照顾收件人、寄件人合理需要，现就有关事项公告如下：</p>
        <p>1)&nbsp;个人邮寄进境物品，海关依法征收进口税，但应征进口税税额在人民币50元（含50元）以下的，海关予以免征。2016年4月8日起，此政策已于终止。</p>
        <p>2)&nbsp;个人寄自或寄往港、澳、台地区的物品，每次限值为800元人民币；寄自或寄往其它国家和地区的物品，每次限值为2000元人民币。</p>
        <p>3)&nbsp;个人邮寄进出境物品超出规定限值的，应办理退运手续或者按照货物规定办理通关手续。但邮包内仅有一件物品且不可分割的，虽超出规定限值，经海关审 &nbsp; &nbsp; &nbsp; &nbsp;核确属个人自用的，可以按照个人物品规定办理通关手续。</p>
        <p>四、邮运进出口的商业性邮件，应按照货物规定办理通关手续。</p>
        <p>五、本公告内容自2010年9月1日起实行。原《海关总署关于调整进出境邮件中个人物品的限值和免税额的通知》（署监〔1994〕774号）同时废止。</p>
        <p><strong>{{ webKeyword.OPEN_COM_NAME }}支持哪些支付方式？</strong></p>
        <p>{{ webKeyword.OPEN_COM_NAME }}目前仅支持海外账户TT、支付宝、微信及网银四种模式支付。</p>
        <p><strong>收货时发现问题可以拒收吗？</strong></p>
        <p>在签收货物时如发现货物有损坏，请直接拒收退回{{ webKeyword.OPEN_COM_NAME }}指定的在内地的仓库，{{ webKeyword.OPEN_COM_NAME }}工作人员确认无误后将会重新安排发货。</p>
        <p><strong>大部分的商品为什么没有防伪标识？</strong></p>
        <p>海外的购物环境比较健全，几乎没有假货仿货的存在，整个商业社会都建立在完善的信用体系上。所以海外销售的商品其实很少有防伪标识，基本没有购物者担心自己会买到假货。{{ webKeyword.OPEN_COM_NAME }}的商品100%海外采购，只销售原本面向海外用户的商品，就是为了确保放心地买到原装正品。</p>
        <p><strong>保健品的包装为什么不够精致？</strong></p>
        <p>欧洲、美国等地保健食品一向比较环保，不会花很多的心思在产品包装上面，不像国内的保健食品，金玉其外。所以买家拿到保健食品之后，不要诧异于它的简洁。另外，国外的保健食品一般都是没有说明书的，其产品的成分、服用方法、注意事项、日期等全部都明确的标识在瓶身上。</p>
        <p><strong>{{ webKeyword.OPEN_COM_NAME }}订单什么时候发货？大概多长时间可以收到货？</strong></p>
        <p>一般商品，自您下单通知我们发货后，我们会在24小时之内为您安排发货，包裹由保税区仓库发出，经海关清关转国内快递配送，时效大约2-5天。进口奶粉如果从香港仓库发货，由于要向海关、商检进行订单信息申报和备案，因此发货时间略长，一般自您下单3个工作日后发货。{{ webKeyword.OPEN_COM_NAME }}平台订单页面会实时更新物流追踪信息。</p>
        <p>&nbsp;</p>
        <p><strong>收到包裹后，发现漏发了或者错发，应该怎么办？</strong></p>
        <p>请先准备好漏发，错发的相关图片（拍摄图片），再通过客服核实，待客服确认后会进行相关处理。</p>
        <p><strong>快递费怎么计算？</strong></p>
        <p>物流配送费用是根据销售订单所含商品进行具体计算的。例如：奶粉分为1件包邮价、2件包邮价。如有疑问，请联系客服人员。</p>
        <p><strong>超重的包裹能通关吗？</strong></p>
        <p>对于重量超过7公斤、货值超过2000元人民币的包裹，需要拆单分成几个包裹以方便通关，我们的后台工作人员会自动为您拆单。</p>
        <p><strong>我司的退换货标准是？</strong></p>
        <p>1：客户接收货物时需要拆箱检查商品是否完好，才能进行签收，如出现商品爆罐、漏粉、商品明显开裂等意外情况，客户可直接拒签，并联系客服退换货，我司不接受非正当无理由拒签，否则我们将收取海关费用和拒签来回运费，请知悉。</p>
        <p>2：未拆箱检查且本人已签收的，出现爆罐或非承诺保质期内情况的，本司均不接受任何退换货处理。</p>
        <p>3：若货物是非本人签收，且出现爆罐、漏粉、明显开裂，请于签收后的次日内（工作日，周六周末顺延）联系我们客服，必须提供异常情况照片和收件快递签单，否则客服有权不予处理。</p>
        <p>4：奶粉属于特殊商品，且欧盟强制要求奶粉厂商使用环保材料，导至奶粉前扣极易在运输过程中掉落，如果您收到的进口奶粉商品有此类情况，但包装内锡纸完好（非人为，未漏气），我司不接受此类理由的退换货。</p>
        <p>5：换货商品须由客户回寄我司指定地址，我司将在客户回寄快递单号后的3个工作日内处理</p>
        <p>6：符合退款规则的订单，由客服联系并确认后于将于3个工作日内处理</p></div>
    </div>
  </div>
</template>

<script>/**
 * @author  XuHongli
 * @date  2022/11/15 10:24
 * @version 1.0
 * @description
 */
import {mapGetters} from 'vuex'
import faqHetun from './components/faqZj'

export default {
  name: 'FAQ',
  components: { faqHetun },
  computed: {
    ...mapGetters(['webKeyword']),
    heTunInfo() {
      if (process.env.NODE_ENV === 'production') {
        return process.env.NODE_ENV
      } else if (process.env.NODE_ENV === 'pre') {
        return process.env.NODE_ENV
      } else if (process.env.NODE_ENV === 'hetun') {
        return process.env.NODE_ENV
      } else {
        return process.env.NODE_ENV
      }
    }
  }
}
</script>

<style scoped>

</style>
