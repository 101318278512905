<template>
  <div>
    <div class="title">常见问题</div>
    <div style="text-align: center; line-height: 20px; margin-top: 10px;">
      发布时间：2022-04-11 20:19:26
    </div>
    <div data-v-0ab10f90="" style="font-size: 14px;"><p>&nbsp;</p>
      <p style="text-indent: 2em;line-height: 27px;">
        <span>深圳市合囤供应链有限责任公司致力打破传统做小而精、专而新的供应链企业通过端到端的协同，为客户和企业创造价值帮助其降低成本，打造覆盖行业广、创新能力强的全品类综合型供应链服务商</span>
      </p>
      <p><strong> <span>深圳市合囤供应链有限责任公司的代发模式是什么？</span> </strong></p>
      <p style="text-indent: 2em;line-height: 27px;">
            <span>深圳市合囤供应链有限责任公司的客户群体是B端私域商城，采购商客户的范围覆盖线上各私域平台（天虹、华润通、碧桂园、快团团、群接龙及各大社群团购平台及盲盒平台）线下实体店等。采购商首先在合囤供应链上筛选需求商品，商品将会同步在采购商的后台，筛选同步完成后采购商可以在自己的销售渠道出售筛选的商品，产生销售订单后信息将通过多种方式导入合囤供应链后台，合囤供应链根据订单信息从将货物从仓库配送到终端消费者手上。当然，采购商也可以先根据自己的销售订单视情况再采购。但是，由于市场的不确定性，我们建议采购商提前采购备货，规避供货紧张或者库存不足的风险。
            </span>
      </p>
      <p><strong> <span>深圳市合囤供应链有限责任公司的货源来源是什么？</span> </strong></p>
      <p style="text-indent: 2em;line-height: 27px;">
        <span>深圳市合囤供应链有限责任公司直对厂家、实力经销商及免税仓、一般贸易进口商保持着深度的合作关系，同时也会在专柜正轨渠道提货。我们承诺所有出自深圳市合囤供应链有限责任公司的商品，为B端客户提供优质安全的产品。</span>
      </p>
      <p><strong> <span>深圳市合囤供应链有限责任公司的货源稳定吗？</span> </strong></p>
      <p style="text-indent: 2em;line-height: 27px;">
        <span>深圳市合囤供应链有限责任公司和国内品牌供应商建立了稳定的合作关系，保证了货源可以持续稳定的供应。我们有专业的团队负责整个供应链环节的监管工作，业务团队及时监控市场并通过高效率的采购工作保证货物的安全库存。</span>
      </p>
      <p><strong> <span>深圳市合囤供应链有限责任公司的商品都能有价格优势，保证采购商的利润空间吗？</span> </strong></p>
      <p style="text-indent: 2em;line-height: 27px;">
        <span>深圳市合囤供应链有限责任公司对每个商品都标注了市场零售价、以及起批价。起批价是指采购商在深圳市合囤供应链有限责任公司采购商品达一定数量时的价格；市场零售价是指具体某个商品在整个市场的平均售价。深圳市合囤供应链有限责任公司的商品定价均十分优惠，主要通过国内厂家的采购以及便捷的物流管理降低成本。</span>
      </p>
      <p><strong> <span>深圳市合囤供应链有限责任公司支持哪些支付方式？</span> </strong></p>
      <p style="text-indent: 2em;"><span>深圳市合囤供应链有限责任公司目前仅支持支付宝、微信及网银等模式支付。</span></p>
      <p><strong> <span>收货时发现问题可以拒收吗？</span> </strong></p>
      <p style="text-indent: 2em;">
        <span>在签收货物时如发现货物有损坏，请直接拒收退回深圳市合囤供应链有限责任公司指定的在内地的仓库，深圳市合囤供应链有限责任公司工作人员确认无误后将会重新安排发货。</span>
      </p>
      <p><strong> <span>深圳市合囤供应链有限责任公司订单什么时候发货？大概多长时间可以收到货？</span> </strong></p>
      <p style="text-indent: 2em;line-height: 27px;">
        <span>一般商品，自您下单通知我们发货后，我们会在48小时之内为您安排发货，包裹由厂家仓库发出，国内快递配送，时效大约2-5天，深圳市合囤供应链有限责任公司平台订单页面会实时更新物流追踪信息。</span>
      </p>
      <p><span> </span></p>
      <p><strong> <span>收到包裹后，发现漏发了或者错发，应该怎么办？</span> </strong></p>
      <p style="text-indent: 2em;"><span>请先准备好漏发，错发的相关图片（拍摄图片），再通过客服核实，待客服确认后会进行相关处理。</span></p>
      <p><strong> <span>我司的退换货标准是？</span> </strong></p>
      <p style="text-indent: 2em;line-height: 27px;"><span>1、客户接收货物时需要拆箱检查商品是否完好，才能进行签收，如出现商品损坏
                明显开裂等意外情况，客户可直接拒签，并联系客服退换货，退换的商品，不可洗涤，不可人为损坏。必须保持包装及吊牌/产品的完整性等原配件完整，原封退回！不接受任何形式的到付！不接受未协商好自行申请退款！</span>
      </p>
      <p style="text-indent: 2em;"><span>我司不接受非正当无理由拒签，否则我们将收取拒签来回运费，请知悉。</span></p>
      <p style="text-indent: 2em;"><span>2、海外直购产品一般情况不予退换货！</span></p>
      <p style="text-indent: 2em;"><span>3、换货商品须由客户回寄我司指定地址，我司将在客户回寄快递单号后的3个工作日内处理</span></p>
      <p style="text-indent: 2em;"><span>4、符合退款规则的订单，由客服联系并确认后于将于3个工作日内处理</span></p>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
  name: 'faqZj',
  computed: {
    ...mapGetters(['webKeyword'])
  }
}
</script>

<style scoped>

</style>
